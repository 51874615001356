import { configureStore } from '@reduxjs/toolkit'
import contactsReducer from './features/contacts.slice'
import LoginReducer from './features/login.slice'
import profileReducer from './features/profile.slice'
import RegisterReducer from './features/register.slice'
import userReducer from './features/users.slice'
import postsReducer from './features/posts.slice'

export const store = configureStore({
    reducer: {
        profile: profileReducer,
        user: userReducer,
        login: LoginReducer,
        register: RegisterReducer,
        contacts: contactsReducer,
        posts: postsReducer
    }
})