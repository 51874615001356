import { createSlice } from "@reduxjs/toolkit"

const userSlice = createSlice({
    name: 'user',
    initialState: {
        userTrigger: false,
        userLists: null,
        userModel: [],
        userColumnName: "Name",
        userOrderType: "ASC",
        userPerPage: "5",
        userPaginationId: 1,
    },
    reducers: {
        setUserTrigger : (state, action) => {
            state.userTrigger = action.payload.userTrigger
        },
        setUserLists : (state, action) => {
            state.userLists = action.payload.userLists
        },
        setUserModel : (state, action) => {
            state.userModel = action.payload.userModel
        },
        setUserColumnName : (state, action) => {
            state.userColumnName = action.payload.userColumnName
        },
        setUserOrderType : (state, action) => {
            state.userOrderType = action.payload.userOrderType
        },
        setUserPerPage : (state, action) => {
            state.userPerPage = action.payload.userPerPage
        },
        setUserPaginationId : (state, action) => {
            state.userPaginationId = action.payload.userPaginationId
        },
    }
});

export const { setUserTrigger, setUserLists, setUserModel, setUserColumnName, setUserOrderType, setUserPerPage, setUserPaginationId } = userSlice.actions;

const userReducer = userSlice.reducer;
export default userReducer;