import { createSlice } from '@reduxjs/toolkit'

const registerSlice = createSlice({
    name: 'register',
    initialState: {
        loader: false,
        showRegisterContent: true,
        email: '',
        name: '',
        surname: '',
        companyName: '',
        pwd: '',
    },
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload.loader;
        },
        setShowRegisterContent: (state, action) => {
            state.showRegisterContent = action.payload.showRegisterContent;
        },
        setName: (state, action) => {
            state.name = action.payload.name;
        },
        setSurname: (state, action) => {
            state.surname = action.payload.surname;
        },
        setEmail: (state, action) => {
            state.email = action.payload.email;
        },
        setCompanyName: (state, action) => {
            state.companyName = action.payload.companyName;
        },
        setPwd: (state, action) => {
            state.pwd = action.payload.pwd;
        },
    }
})

export const { setLoader, setShowRegisterContent, setName, setSurname, setEmail, setCompanyName, setPwd } = registerSlice.actions;

const RegisterReducer = registerSlice.reducer;
export default RegisterReducer;
