import { createSlice } from "@reduxjs/toolkit";

const contactsSlice = createSlice({
    name: 'contacts',
    initialState: {
        contactTrigger: false,
        /* Contact List */
        contactList: [],
        /* Contact Edit */
        editContactNamesurname: '',
        editContactEmail: '',
        editContactPhone: '',
        contactId: ''
    },
    reducers: {
        setContactTrigger: (state, action) => {
            state.contactTrigger = action.payload.contactTrigger;
        },
        /* Contact List */
        setContactList: (state, action) => {
            state.contactList = action.payload.contactList;
        },
        /* Contact Edit */
        setEditContactNamesurname : (state, action) => {
            state.editContactNamesurname = action.payload.editContactNamesurname;
        },
        setEditContactEmail : (state, action) => {
            state.editContactEmail = action.payload.editContactEmail;
        },
        setEditContactPhone : (state, action) => {
            state.editContactPhone = action.payload.editContactPhone;
        },
        setContactId : (state, action) => {
            state.contactId = action.payload.contactId;
        },
    }
})

export const { setContactTrigger, setContactList, setEditContactNamesurname, setEditContactEmail, setEditContactPhone, setContactId } = contactsSlice.actions;

const contactsReducer = contactsSlice.reducer;
export default contactsReducer;