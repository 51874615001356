import { createSlice } from "@reduxjs/toolkit"

const postSlice = createSlice({
    name: 'posts',
    initialState: {
        postTrigger: false,
        postLists: null,
        postModel: [],
        postColumnName: "Title",
        postOrderType: "ASC",
        postPerPage: "5",
        postPaginationId: 1,
    },
    reducers: {
        setpostTrigger : (state, action) => {
            state.postTrigger = action.payload.postTrigger
        },
        setPostLists : (state, action) => {
            state.postLists = action.payload.postLists
        },
        setPostModel : (state, action) => {
            state.postModel = action.payload.postModel
        },
        setPostColumnName : (state, action) => {
            state.postColumnName = action.payload.postColumnName
        },
        setPostOrderType : (state, action) => {
            state.postOrderType = action.payload.postOrderType
        },
        setPostPerPage : (state, action) => {
            state.postPerPage = action.payload.postPerPage
        },
        setPostPaginationId : (state, action) => {
            state.postPaginationId = action.payload.postPaginationId
        },
    }
});

export const { setpostTrigger, setPostLists, setPostModel, setPostColumnName, setPostOrderType, setPostPerPage, setPostPaginationId } = postSlice.actions;

const postsReducer = postSlice.reducer;
export default postsReducer;