import { createSlice } from '@reduxjs/toolkit'

const loginSlice = createSlice({
    name: 'login',
    initialState: {
        loader: false,
        showLoginContent: true,
        email: '',
        pwd: '',
        postLoginData: '',
    },
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload.loader;
        },
        setShowLoginContent: (state, action) => {
            state.showLoginContent = action.payload.showLoginContent;
        },
        setEmail: (state, action) => {
            state.email = action.payload.email;
        },
        setPwd: (state, action) => {
            state.pwd = action.payload.pwd;
        },
        setPostLoginData: (state, action) => {
            state.postLoginData = action.payload.postLoginData;
        },
    }
})

export const { setLoader, setShowLoginContent, setEmail, setPwd, setPostLoginData } = loginSlice.actions;

const LoginReducer = loginSlice.reducer;
export default LoginReducer;
