import { createSlice } from "@reduxjs/toolkit";

const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        profileTrigger: false,
        /* Profile Page */
        profileName: '',
        profileSurname: '',
        profileEmail: '',
        profilePhone: '',
        profileCompanyName: '',
        /* Edit Profile Page */
        pwd : null,
        confirmPwd: null
    },
    reducers: {
        setProfileTrigger: (state, action) => {
            state.profileTrigger = action.payload.profileTrigger
        },
        /* Profile Page */
        setProfileName: (state, action) => {
            state.profileName = action.payload.profileName
        },
        setProfileSurname: (state, action) => {
            state.profileSurname = action.payload.profileSurname
        },
        setProfileEmail: (state, action) => {
            state.profileEmail = action.payload.profileEmail
        },
        setProfilePhone: (state, action) => {
            state.profilePhone = action.payload.profilePhone
        },
        SetProfileCompanyName: (state, action) => {
            state.profileCompanyName = action.payload.profileCompanyName
        },
        /* Edit Profile Page */
        setPwd: (state, action) => {
            state.pwd = action.payload.pwd
        },
        setConfirmPwd: (state, action) => {
            state.confirmPwd = action.payload.confirmPwd
        }
    }
});

export const { setProfileTrigger, setProfileName, setProfileSurname, setProfileEmail, setProfilePhone, SetProfileCompanyName, setPwd, setConfirmPwd } = profileSlice.actions

const profileReducer = profileSlice.reducer;
export default profileReducer;